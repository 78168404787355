








































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPaging,
} from "devextreme-vue/data-grid";
import { mediciones_kpi } from "@/shared/dtos/mediciones_kpi";
import mediciones_kpiModule from "@/store/modules/mediciones_kpi-module";
import kpis_actuacionModule from "@/store/modules/kpis_actuacion-module";

@Component({
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
  },
  $_veeValidate: { validator: "new" },
})
export default class KpisMediciones extends Vue {
  @PropSync("id", { default: 0 }) XId!: number;
  public mediciones_kpis: mediciones_kpi[] = [];

  public created() {
    mediciones_kpiModule
      .getmediciones_kpi_bykpi(this.XId)
      .then((x: mediciones_kpi[]) => (this.mediciones_kpis = x));
  }

  public get caption() {
    return "Valor en " + this.medicion;
  }

  public get medicion() {
    return kpis_actuacionModule.kpis_actuaciones.find((x) => x.id === this.XId)!
      .unidades;
  }

  Eliminar(evento: any) {
    mediciones_kpiModule.eliminarmediciones_kpi(
      new mediciones_kpi(evento.data)
    );
  }
  Guardar(evento: any) {
    if (evento.cancel === undefined) {
      for (let index = 0; index < evento.changes.length; index++) {
        if (evento.changes[index].data !== undefined) {
          const element: mediciones_kpi = new mediciones_kpi(
            evento.changes[index].data
          );
          element.id_kpis_actuacion = this.XId;
          if (typeof element.id === "string") {
            element.id = 0;
            mediciones_kpiModule.guardarmediciones_kpi(element).then(() => {
              mediciones_kpiModule
                .getmediciones_kpi_bykpi(this.XId)
                .then((x: mediciones_kpi[]) => (this.mediciones_kpis = x));
            });
          } else {
            mediciones_kpiModule.modificarmediciones_kpi(element).then(() => {
              mediciones_kpiModule
                .getmediciones_kpi_bykpi(this.XId)
                .then((x: mediciones_kpi[]) => (this.mediciones_kpis = x));
            });
          }
        }
      }
    }
  }

  logEvent(eventName: any[]) {
    //this.events.unshift(eventName);
  }
}
