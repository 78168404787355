import { store } from '@/store/store';
import { mediciones_kpi } from '@/shared/dtos/mediciones_kpi';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'mediciones_kpiModule',
    store,
    dynamic: true
})
class mediciones_kpiModule extends VuexModule {
    public mediciones_kpis: mediciones_kpi[] = [];
    public mediciones_kpi: mediciones_kpi = new mediciones_kpi();

    @Action({ commit: 'onGetmediciones_kpis' })
    public async getmediciones_kpis() {
        return await ssmHttpService.get(API.mediciones_kpi);
    }

    @Action({ commit: 'onGetmediciones_kpi' })
    public async getmediciones_kpi(id: any) {
        return await ssmHttpService.get(API.mediciones_kpi + '/' + id);
    }

    @Action({ commit: 'onGetmediciones_kpis' })
    public async getmediciones_kpi_bykpi(id: any) {
        return await ssmHttpService.get(API.mediciones_kpi + '/mediciones/' + id);
    }


    @Action
    public async guardarmediciones_kpi(mediciones_kpi: mediciones_kpi) {
       return await ssmHttpService.post(API.mediciones_kpi, mediciones_kpi.toJson());
    }

    @Action
    public async modificarmediciones_kpi(mediciones_kpi: mediciones_kpi) {
        return await ssmHttpService.put(API.mediciones_kpi + '/' + mediciones_kpi.id, mediciones_kpi);
    }

    @Action
    public async eliminarmediciones_kpi(mediciones_kpi: mediciones_kpi) {
       return await ssmHttpService.delete(API.mediciones_kpi + '/' + mediciones_kpi.id, null, false);
    }

    @Mutation
    public onGetmediciones_kpis(res: mediciones_kpi[]) {
        this.mediciones_kpis = res ? res.map((x) => new mediciones_kpi(x)) : []
    }

    @Mutation
    public onGetmediciones_kpi(res: mediciones_kpi) {
        this.mediciones_kpi = new mediciones_kpi(res);
    }

}
export default getModule(mediciones_kpiModule);